<template>
  <main class="my-main">
    <template v-if="navChilds.length || this.$route.meta.isDetails">
      <!-- banner -->
      <section class="main-banner">
        <el-image class="wh100" :src="$base+nav.title" alt=""></el-image>
      </section>
      <section class="justify-between content">
        <!-- 子路由导航 -->
        <div v-if="!this.$route.meta.isDetails">
          <div class="navs-child">
            <div class="navs-title flex-center">
              {{nav.name}}
            </div>
            <ul class="navs-list">
              <li class="navs-item" v-for="(nav,index) in navChilds" :key="index">
                <router-link :to="nav.route_url">{{nav.name}}</router-link>
              </li>
            </ul>
          </div>
        </div>
        <router-view class="main-content" />
      </section>
    </template>

    <template v-else>
      <router-view />
    </template>

    <!-- 在线咨询浮窗 -->
    <!-- <Consulting /> -->

  </main>
</template>

<script>
import Consulting from '@/components/Consulting'

export default {
  name: 'Main',
  props: {
    'navs': Array
  },
  components: {
    Consulting
  },
  data () {
    return {
      nav: {},
      navChilds: [],
    };
  },
  created () {
    this.changeNav(this.$route)
  },
  watch: {
    $route (to, from) {
      if (to.meta.isStatic) {
        this.navChilds = []
      } else {
        this.changeNav(to)
      }
    }
  },
  methods: {
    changeNav (route) {
      // 不是新闻详情页执行
      if (route.meta.isDetails) {
        this.nav = this.navs.find(i => i.code == route.name)
      } else {
        this.findNav(this.navs, route)
        sessionStorage.setItem('route_url', route.path)
      }
    },
    // 获取对应的子级路由
    findNav (navs, to) {
      navs.forEach(item => {
        if (item.route_url == to.path) {
          if (item.parent_tag) {
            this.$store.dispatch('setNavItem', item)
            this.nav = this.navs.find(i => i.tag == item.parent_tag)
            this.navChilds = this.nav.client_title
          } else {
            this.navChilds = []
          }
          return this.navChilds
        }
        if (item.client_title) {
          this.findNav(item.client_title, to)
        }
      })
      return this.navChilds
    }
  },
};
</script>

<style scoped lang="less">
.my-main {
  display: block;
  background-color: #f6f6f6;
  padding-bottom: 100px;
}
.main-banner {
  margin-bottom: 40px;
}
.navs-child {
  width: 188px;
  background: #ffffff;
  box-shadow: 0px 0px 13px 0px rgba(54, 54, 54, 0.1);
  border-radius: 95px;
  padding-bottom: 85px;
  .navs-title {
    height: 188px;
    background: url("../assets/images/main-bg1.png") no-repeat center;
    color: #ffffff;
    font-size: 24px;
    font-family: "STXingkai";
  }
  .navs-list {
    margin-top: 22px;
    .navs-item {
      line-height: 65px;
      text-align: center;
      .router-link-active {
        background-color: #fbd690;
        font-weight: bold;
      }
      &:hover {
        a {
          color: #fbd690;
        }
        .router-link-active {
          color: #000000;
        }
      }
    }
  }
}
.main-content {
  width: 80%;
  flex: 1;
  margin-top: 30px;
  margin-left: 30px;
}
</style>
