import axios from 'axios'
import store from '@/store'
const request = axios.create({
  baseURL: process.env.VUE_APP_BASE_URL
})
// 请求拦截器
request.interceptors.request.use(
  // 发送请求之前
  function (config) {
    const { user } = store.state

    // 利用逻辑运算符简化if嵌套
    user && config.headers.token &&
      (config.headers.Authorization = `Bearer ${user.token}`)

    return config
  },
  // 请求错误
  function (error) {
    return Promise.reject(error)
  }
)
// 响应拦截器
request.interceptors.response.use(
  // 响应成功
  function (response) {
    return response.data || response
  },
  // 响应错误
  async function (error) {
    return Promise.reject(error)
  }
)
export default request