<template>
  <footer class="my-footer">
    <div class="content">
      <!-- 内部链接 -->
      <ul class="company-internal flex-center">
        <li
          class="internal-item"
          v-for="item in getConfigs.url_Internal_links"
          :key="item.id"
        >
          <a :href="item.details" target="_blank">{{ item.display_name }}</a>
        </li>
      </ul>
      <div class="justify-between">
        <div class="footer-company">
          <!-- 公司logo+名称 -->
          <div class="align-center">
            <img
              class="below_logo"
              :src="$base + getConfigs.below_logo"
              alt=""
            />
            <img
              style="margin-left: 20px;"
              :src="require('@/assets/images/logo-wzw-white.png')"
              alt=""
            />
          </div>
          <p class="company-name">{{ getConfigs.title }}</p>
          <!-- 联系方式 -->
          <div class="company-phone justify-between">
            <ul>
              <li class="phone-label">工作日[咨询电话]:</li>
              <li
                class="phone-val"
                v-for="(item, index) in getConfigs.tel.split(',')"
                :key="index"
              >
                {{ item }}
              </li>
            </ul>
            <ul>
              <li class="phone-label">7x24小时[咨询电话]:</li>
              <li
                class="phone-val"
                v-for="(item, index) in getConfigs.phone.split(',')"
                :key="index"
              >
                {{ item }}
              </li>
            </ul>
          </div>
          <!-- 公司地址 -->
          <p class="company-text">公司邮箱：{{ getConfigs.email }}</p>
          <p class="company-text">公司总地址：{{ getConfigs.address }}</p>
        </div>

        <div class="footer-other justify-between">
          <div>
            <h6>友情链接：</h6>
            <el-row :gutter="15" class="company-blogroll align-center">
              <el-col
                v-for="item in getConfigs.url_blogroll"
                :key="item.id"
                :span="8"
              >
                <a :href="item.details" target="_blank">
                  <el-image :src="$base + item.value"></el-image>
                </a>
              </el-col>
            </el-row>
            <p class="company-record">
              <a
                class="company-record-ICP"
                href="https://beian.miit.gov.cn/"
                target="_blank"
                rel="noopener noreferrer"
              >
                ICP备案号：{{ getConfigs.icp_number }}
              </a>
              <span id="cnzz_stat_icon">
                <a :href="$cnzz" target="_blank" title="站长统计">
                  <img
                    border="0"
                    hspace="0"
                    vspace="0"
                    src="https://icon.cnzz.com/img/pic.gif"
                  />
                </a>
              </span>
            </p>
          </div>
          <!-- 二维码 -->
          <div class="company-qrCode">
            <el-image :src="$base + getConfigs.Focus" alt=""></el-image>
            <p>关注我们扫一扫</p>
          </div>
        </div>
      </div>
    </div>
  </footer>
</template>

<script>
import { mapGetters } from 'vuex';

export default {
  computed: {
    ...mapGetters(['getConfigs'])
  }
};
</script>

<style scoped lang="less">
.my-footer {
  background-color: #97846c;
  padding: 60px 0 40px;
}
.company-internal {
  margin-bottom: 52px;
  .internal-item {
    width: 18%;
    line-height: 50px;
    text-align: center;
    background-color: #ffffff;
    border-radius: 25px;
    margin: 0 19px;
  }
}
.footer-company {
  .below_logo {
    width: 126px;
    height: 34px;
  }
  .company-name {
    font-weight: bold;
    color: #ffffff;
    font-size: 18px;
    margin-top: 16px;
  }
  .company-phone {
    margin: 34px 0 20px;
    ul {
      .phone-label {
        font-size: 16px;
        color: #eeeeee;
        margin-bottom: 18px;
        letter-spacing: 1px;
      }
      .phone-val {
        font-size: 18px;
        font-weight: bold;
        color: #eeeeee;
        margin-bottom: 14px;
        letter-spacing: 2px;
      }
    }
  }
  .company-text {
    color: #eeeeee;
    line-height: 23px;
    letter-spacing: 1px;
  }
}
.footer-other {
  width: 50%;
  align-self: flex-end;
  h6 {
    color: #d5d5d5;
  }
  .company-blogroll {
    margin: 20px 0 40px;
  }
  .company-record {
    max-width: 90%;
    a {
      display: inline;
      font-size: 14px;
      color: #d5d5d5;
      line-height: 24px;
    }
    #cnzz_stat_icon {
      margin-left: 20px;
    }
  }
  .company-qrCode {
    text-align: center;
    margin-left: 100px;
    .el-image {
      width: 136px;
      margin-bottom: 6px;
    }
    p {
      font-size: 18px;
      color: #ffffff;
      letter-spacing: 1px;
    }
  }
}
</style>
