<template>
  <el-dialog class="flex-center" title="用户登录" :visible.sync="dialogFormVisible" center @close="close">
    <img class="" :src="$base+getConfigs.logo" alt='logo' />
    <el-form :model="loginForm" :rules="rules" ref="loginForm">
      <el-form-item prop="phone">
        <el-input v-model="loginForm.phone" placeholder="请输入手机号" prefix-icon="el-icon-mobile-phone"></el-input>
      </el-form-item>
      <el-form-item prop="code">
        <el-input v-model="loginForm.code" placeholder="请输入验证码" prefix-icon="el-icon-message">
          <el-button class="send-code" slot="append" :disabled="isDisabled" :loading="isLoading"
            @click="getCode('loginForm')">{{codeMsg}}
          </el-button>
        </el-input>
      </el-form-item>
      <el-form-item>
        <el-button type="warning" :loading="isLoginLoading" @click="login('loginForm')">登录/注册</el-button>
      </el-form-item>
    </el-form>
  </el-dialog>
</template>
<script>
import { mapGetters } from "vuex"
import { getUser, sendCode } from '@/api'
export default {
  name: "login",

  computed: {
    ...mapGetters(['getConfigs', 'getShowLogin'])
  },
  data () {
    var validatePhone = (rule, value, callback) => {
      if (!(/^1[3456789]\d{9}$/.test(value))) {
        callback(new Error('手机号格式错误'));
      } else {
        callback();
      }
    };
    var validateCode = (rule, value, callback) => {
      if (!(/^\d{6}$/.test(value))) {
        callback(new Error('验证码格式错误'));
      } else {
        callback();
      }
    };
    return {
      dialogFormVisible: this.getShowLogin,
      timer: null, // 倒计时
      isLoading: false, // 发送验证码成功之前加载
      isDisabled: false, // 发送验证码后禁用
      count: 0, // 验证码倒计时
      codeMsg: '获取验证码',
      isLoginLoading: false, // 登录加载状态
      loginForm: {
        phone: '',
        code: ''
      },
      rules: {
        phone: [
          { required: true, message: '请输入手机号', trigger: 'blur' },
          { validator: validatePhone, trigger: 'blur' }
        ],
        code: [
          { required: true, message: '请输入验证码', trigger: 'submit' },
          { validator: validateCode, trigger: 'submit' }
        ]
      }
    }
  },
  watch: {
    getShowLogin (newVal) {
      this.dialogFormVisible = newVal
    }
  },
  mounted () {
  },
  methods: {
    // 登录框关闭
    close () {
      this.$store.dispatch("setShowLogin", false)
      this.endTimer("获取验证码")
    },
    //  获取验证码
    getCode (formName) {
      this.$refs[formName].validateField("phone", async valid => {
        if (!valid) {
          this.codeMsg = '加载中'
          this.isLoading = true
          let { status, msg } = await sendCode(this.loginForm.phone)
          if (status == 200) {
            this.countDown(60)
            this.$message.success(msg);
          } else {
            this.endTimer()
            this.$message.error(msg);
          }
        } else {
          return false;
        }
      })
    },

    // 登录
    login (formName) {
      this.$refs[formName].validate(async (valid) => {
        if (valid) {
          this.isLoginLoading = true
          let { data, msg, status } = await getUser(this.loginForm)
          if (status == 200 && data) {
            this.$message.success('登陆成功');
            this.$store.dispatch('setUser', data)
            this.$refs[formName].resetFields();
            this.close()
          } else {
            this.$message.error(msg);
          }
          this.isLoginLoading = false
        } else {
          return false;
        }
      });
    },

    // 开始倒计时
    countDown (num) {
      if (!this.timer) {
        this.count = num
        this.codeMsg = `${num}秒`
        this.isDisabled = true
        this.isLoading = false
        this.timer = setInterval(() => {
          if (this.count > 0 && this.count <= num) {
            this.count--
            this.codeMsg = `${this.count}秒`
          } else {
            this.endTimer()
          }
        }, 1000)
      }
    },
    // 结束倒计时
    endTimer (msg) {
      this.isDisabled = false
      this.isLoading = false
      clearInterval(this.timer)
      this.timer = null
      this.codeMsg = msg || '重新发送'
    }
  },
}
</script>

<style lang="less" scoped>
/deep/ .el-dialog {
  width: 25%;
  margin-top: 0 !important;
  .el-dialog__body {
    text-align: center;
    padding: 15px 25px;
  }
}
/deep/ .el-form {
  margin-top: 20px;
  .el-form-item__content > .el-button {
    width: 100%;
  }
  .el-input {
    .el-input__icon {
      font-size: 18px;
    }
    .el-input-group__append {
      background-color: transparent;
      .el-button {
        padding: 0 10px;
        border: none;
        color: #65b1ff;
      }
    }
  }
}
.send-code {
  height: 100%;
}
</style>