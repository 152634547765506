<template>
  <div id="app">
    <template v-if="this.$store.getters.getConfigs">
      <Header :navs="navs" />
      <Main :navs="navs" />
      <Footer />
    </template>
    <!-- 进入网站加载效果 -->
    <template v-else>
      <div v-loading.fullscreen.lock="!this.$store.getters.getConfigs"></div>
    </template>
  </div>
</template>
<script>
import { getNavs, getConfig } from '@/api'
import Header from '@/components/Header'
import Main from '@/components/Main'
import Footer from '@/components/Footer'
export default {
  components: {
    Header,
    Main,
    Footer
  },
  data () {
    return {
      navs: []
    };
  },
  created () {
    // 移动端展示
    let flag = navigator.userAgent.match(/(phone|pad|pod|iPhone|iPod|ios|iPad|Android|Mobile|BlackBerry|IEMobile|MQQBrowser|JUC|Fennec|wOSBrowser|BrowserNG|WebOS|Symbian|Windows Phone)/i);
    if (flag) {
      window.location.href = "http://wap.chinacpv.com"
    }

  },
  async mounted () {
    // 获取头部导航
    let navRes = await getNavs()
    this.navs = navRes.data
    // 获取网站配置
    let configRes = await getConfig()
    this.$store.dispatch('setConfigs', configRes.data)

    // 网站录入统计
    const script = document.createElement('script');
    script.src = this.$pvuv;
    script.language = 'JavaScript';
    script.id = 'cnzz';
    document.body.appendChild(script);
  },
  watch: {
    $route (to, form) {
      // 网站访问收录
      if (window._czc) {
        let location = window.location;
        let contentUrl = location.pathname + location.hash;
        let refererUrl = "/";
        window._czc.push(["_trackPageview", contentUrl, refererUrl]);
      }
    }
  },
}
</script>

<style scoped lang="less">
/deep/ .el-image__inner--center {
  top: 0;
  left: 0;
  transform: none;
}
</style>