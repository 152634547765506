// 首页模块标题
<template>
  <div class="home-title">
    <span class="title-text" ref="title" :style="{color}">{{title}}</span>
    <span class="title-line" :style="{'height':titleHright+'px'}"></span>
    <span class="title-subtitle" v-for="(item,index) in subtitle" :key="index"
      :style="{color:subtitleColor}">{{item.toLocaleUpperCase()}}</span>
  </div>
</template>

<script>
export default {
  props: {
    'title': String,
    'subtitle': Array,
    'color': String,
    'subtitleColor': String
  },
  data () {
    return {
      titleHright: ''
    };
  },
  computed: {

  },
  created () {

  },
  mounted () {
    this.titleHright = this.$refs.title.offsetHeight
  },
  watch: {

  },
  methods: {

  },
  components: {

  },
};
</script>

<style scoped lang="less">
.home-title {
  min-width: 162px;
  float: left;
  margin-top: 20px;
}
span {
  float: right;
  -webkit-writing-mode: vertical-rl;
  writing-mode: vertical-rl;
}
.title-text {
  font-size: 30px;
  font-weight: bold;
  letter-spacing: 2px;
}
.title-line {
  display: block;
  width: 10px;
  background-color: #f1b54c;
  margin: 0 11px 0 15px;
}
.title-subtitle {
  font-size: 48px;
  font-weight: bold;
}
</style>
