var render = function () {
var this$1 = this;
var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"home-ywfb "},[_c('div',{staticClass:"ywfb-map"},_vm._l((_vm.ywfb),function(item,index){return _c('div',{key:index,staticClass:"flex-center ywfb-map-item",on:{"click":function($event){return _vm.changeArea(index)}}},[_c('div',{class:[
          'ywfb-map-default',
          index == _vm.ywfbActive ? 'ywfb-map-ani' : ''
        ]}),_c('span',[_vm._v(_vm._s(item.name))])])}),0),_c('div',{staticClass:"ywfb-list"},[_c('div',{staticClass:"ywfb-list-header justify-around"},[_c('div',{staticClass:"honor-arrow honor-left flex-center",on:{"click":function () {
            this$1.$refs.newsCardShow.prev();
          }}},[_c('el-image',{attrs:{"src":require('@/assets/images/arrow-left.png'),"alt":""}})],1),_c('el-carousel',{ref:"newsCardShow",attrs:{"height":"50px","indicator-position":"none","arrow":"never","autoplay":false,"initial-index":_vm.ywfbActive},on:{"change":function (ind) {
            _vm.ywfbActive = ind;
          }}},_vm._l((_vm.ywfb),function(item,index){return _c('el-carousel-item',{key:index},[_c('h3',{staticClass:"small"},[_vm._v(_vm._s(item.name))])])}),1),_c('div',{staticClass:"honor-arrow honor-right flex-center",on:{"click":function () {
            this$1.$refs.newsCardShow.next();
          }}},[_c('el-image',{attrs:{"src":require('@/assets/images/arrow-right.png'),"alt":""}})],1)],1),_c('div',{staticClass:"ywfb-list-content "},[_c('p',[_vm._v(" "+_vm._s(_vm.ywfb[_vm.ywfbActive] ? _vm.ywfb[_vm.ywfbActive].describe : '')+" ")])])])])}
var staticRenderFns = []

export { render, staticRenderFns }