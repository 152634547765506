<template>
  <div class="home">
    <!-- banner卷轴 -->
    <section class="home-banner">

      <!-- banner卷轴 -->
      <div class="home-one content">
        <!-- <Jz /> -->
        <el-image class="home-jz home-jz-left" :src="require('../../assets/images/home-bn1-left.png')"></el-image>
        <el-image class="home-jz home-jz-right" :src="require('../../assets/images/home-bn1-right.png')"></el-image>
        <div class="home-jz-center"></div>
      </div>
      <!-- 滚动通知 -->
      <div class="content home-notice ">
        <router-link :to="{path:'/news/newsDetails',query:{type:newNews.class_type,id:newNews.id}}"
          class="justify-between-center">
          <div class="align-center">
            <el-image :src="require('@/assets/images/home-notice.png')" alt='通知'></el-image>
            <span class="home-notice-text">{{newNews.biaoti}}</span>
          </div>
          <span class="home-notice-time" v-show="newNews.created_at">{{newNews.created_at|filterTime}}</span>
        </router-link>
      </div>
      <!-- 核心业务 -->
      <section class="home-two ">
        <div class="content justify-between">
          <HomeTitle title="核心业务" :subtitle="['core','business']" color="#585858" subtitleColor="#B4AFAF" />
          <div class="home-hxyw mg-l50 align-center">
            <el-image :src="$base+getConfigs.core_business" alt='核心业务'></el-image>
          </div>
        </div>
      </section>

    </section>
    <!-- 荣誉图集 -->
    <section class="home-three">
      <div class="content">
        <div class="home-honor align-center">
          <div class="honor-box">
            <ul class="honor-list justify-between-center flex-warp">
              <li class="honor-item" v-for="(item,index) in hyryAvtive" :key="index">
                <img class="wh100" :src="$base+item.logo" alt="">
              </li>
            </ul>
          </div>
          <i class="el-icon-arrow-left honor-left" @click="leftClick()"></i>
          <i class="el-icon-arrow-right honor-right" @click="rightClick()"></i>
        </div>
      </div>
    </section>
    <!-- 核心优势 -->
    <section class="home-four pd68">
      <div class="content justify-between">
        <HomeTitle title="核心优势" :subtitle="['core','business']" color="#585858" subtitleColor="#B4AFAF" />
        <ul class="home-hxys hxys-list mg-l50 justify-between flex-warp">
          <li class="hxys-item" v-for="item in hxyss" :key="item.id"
            :style="`background-image:url(${$base+item.standby_image}) `">
            <router-link to="/corestrengths" class="flex-center">
              <el-image class="hxys-img" :src="$base+item.picture"></el-image>
              <i class="hxys-line"></i>
              <p class="hxys-title">{{item.name}}</p>
              <div class="hxys-info">
                <p class="text-ellipsis-lines">{{item.describe}}</p>
              </div>
            </router-link>
          </li>
        </ul>
      </div>
    </section>
    <!-- 新闻动态 -->
    <section class="home-five pd68">
      <div class="content justify-between">
        <HomeTitle title="新闻动态" :subtitle="['news','information']" color="#5F5F5F" subtitleColor="#B4AFAF" />
        <el-tabs class="home-xwdt" v-model="newsActiveName">
          <el-tab-pane v-for="(item,index) in newsTitle" :key="index" :label="item.label" :name="item.name">
            <ul class="xwdt-list">
              <li class="xwdt-item" v-for="list in news[item.name]" :key="list.id">
                <router-link :to="{path:'/news/newsDetails',query:{type:list.class_type,id:list.id}}">
                  <p class="justify-between"><span class="news-title">{{list.biaoti}}</span><span
                      class="news-time">{{list.created_at|filterTime}}</span></p>
                  <p class="justify-between"><span class="news-info text-ellipsis">{{list.sy_title}}</span>
                    <el-image :src="require('@/assets/images/home-xwdt1.png')" alt=""></el-image>
                  </p>
                </router-link>
              </li>
            </ul>
            <div class=" news-btn">
              <router-link :to="{ name: newsActiveName }" class="home-btns align-center">
                <el-button type="info"> 更多 </el-button>
                <img :src="require('@/assets/images/home-xwdt2.png')" alt="" />
                <el-button type="warning align-center"> </el-button>
              </router-link>
            </div>
          </el-tab-pane>
        </el-tabs>
      </div>
    </section>
    <!-- 业务分布 -->
    <section class="home-six">
      <div class="content justify-between-center">
        <HomeTitle title="业务分布" :subtitle="['Business','distribution']" color="#FFFFFF" subtitleColor="#808080" />
        <BusinessMap class="mg-l50" />
      </div>
    </section>
    <!-- 关于我们 -->
    <section class="home-seven pd68">
      <div class="content justify-between">
        <HomeTitle title="关于我们" :subtitle="['About','us']" color="#424242" subtitleColor="#BFBBBB" />
        <div class="home-gywm mg-l50 justify-between-center">
          <img :src="require('@/assets/images/home-gywm1.png')" />
          <p>
            {{getConfigs.aboutus}}</p>
          <div class="gywm-btn">
            <router-link to="/company/profile" class="home-btns align-center">
              <el-button type="info"> 更多 </el-button>
              <img :src="require('@/assets/images/home-xwdt2.png')" alt="" />
              <el-button type="warning align-center"> </el-button>
            </router-link>
          </div>
        </div>
      </div>
    </section>
    <!-- 核心客户 -->
    <section class="home-eight pd68">
      <div class="content justify-between">
        <HomeTitle title="核心客户" :subtitle="['Core','customers']" color="#424242" subtitleColor="#BFBBBB" />
        <div class="home-hxkh mg-l50">
          <el-tabs v-model="hxkhActiveName" @tab-click="hxkhHandleClick">
            <el-tab-pane :name="item.tag" v-for="(item,index) in hxkhs" :key="index">
              <span slot="label">{{item.name}}<el-divider direction="vertical"></el-divider> </span>
            </el-tab-pane>
          </el-tabs>
          <el-carousel ref="hxkhCardShow" indicator-position="outside" trigger="click" arrow="never" :autoplay='false'
            :initial-index='hxkhActive' @change="(ind)=>{hxkhActiveName = hxkhs[ind].tag}">
            <el-carousel-item v-for="(item,index) in hxkhs" :key="index">
              <ul class="hxkh-list justify-between-center flex-warp">
                <li class="hxkh-item flex-center" v-for="(list,index1) in item.client_datum" :key="index1">
                  <img class="hxkh-logo" v-if="list.logo" :src="$base+list.logo" :alt="list.name" />
                  <span class="hxkh-name" v-else>{{list.name}}</span>
                </li>
              </ul>
            </el-carousel-item>
          </el-carousel>
        </div>
      </div>
    </section>

  </div>
</template>

<script>
import { getHomeNews, getCase, getCore, getCust } from '@/api'
import { mapGetters } from "vuex"
import HomeTitle from '@/components/HomeTitle'
import BusinessMap from '@/components/BusinessMap'
import Jz from '@/components/Jz'
import { throttle } from '@/utils/utils'
export default {
  name: 'Home',
  components: {
    HomeTitle,
    BusinessMap,
    Jz,
  },
  computed: {
    ...mapGetters(['getConfigs', 'getCores'])
  },
  data () {
    return {
      newNews: {}, // 滚动通知最新新闻
      hyrys: [], // 所有的荣誉图片数据
      hyryAvtive: [], // 要展示的荣誉数据
      hxyss: [], // 核心优势
      newsTitle: [{ label: '公司新闻', name: 'company' }, { label: '业界新闻', name: 'industry' }, { label: '经典案例', name: 'case' }], // 新闻标签页
      newsActiveName: 'company',// 选中的的新闻标签页
      news: [],//新闻动态
      hxkhs: [], //核心客户数据
      hxkhActiveName: 'hzyh',// 选中的核心客户标签页
      hxkhActive: 0, // 核心客户轮播下标
    }
  },
  mounted () {
    // 卷轴效果
    $('.home-jz-center').stop(true).animate({ width: '100%' }, 1500)
    $('.home-jz-left').stop(true).animate({ left: '0' }, 1400)
    $('.home-jz-right').stop(true).animate({ right: '0' }, 1380)

    this.getData()
  },
  methods: {

    // 获取数据
    async getData () {
      // 滚动通知新闻
      try {
        let newsRes = await getHomeNews()
        this.newNews = newsRes.data.latest_new
        this.news = newsRes.data
      } catch (error) {
        console.log("网络错误");
      }
      // 行业荣誉
      try {
        let hyryRes = await getCase('hyry')
        this.hyrys = hyryRes.data
        this.getHyrysActive(this.hyrys.length - 1)
      } catch (error) {
        console.log("网络错误");
      }
      // 核心优势
      try {
        let hxysRes = await getCore('hxys')
        this.hxyss = hxysRes.data
      } catch (error) {
        console.log("网络错误");
      }
      // 核心客户
      try {
        let hxkhRes = await getCust()
        this.hxkhs = hxkhRes.data
        this.hxkhActiveName = this.hxkhs[this.hxkhActive].tag
        for (let i = 0; i < this.hxkhs.length; i++) {
          this.hxkhs[i].client_datum = this.hxkhs[i].client_datum.splice(0, 15)
        }
      } catch (error) {
        console.log("网络错误");
      }
    },
    // 向左切换荣誉图集
    leftClick () {
      throttle(() => {
        this.changeHyrys('left')
      }, 500)
    },
    // 向右切换荣誉图集
    rightClick () {
      throttle(() => {
        this.changeHyrys('right')
      }, 500)
    },
    // 获取展示的荣誉数据
    getHyrysActive (ind) {
      for (let i = ind; i < this.hyrys.length; i++) {
        this.hyryAvtive.push(this.hyrys[i])
        if (this.hyryAvtive.length >= 14) {
          break
        } else {
          if (i == this.hyrys.length - 1) {
            this.getHyrysActive(0)
          }
        }
      }
    },
    // 切换荣誉数据时的效果逻辑
    changeHyrys (type) {
      let leftNum = parseFloat($('.honor-list').css('left'))
      let leftResults = type == 'left' ? leftNum - leftNum : leftNum + leftNum
      $('.honor-list').stop().animate({ left: leftResults }, 300)
      setTimeout(() => {
        if (type == 'left') {
          this.hyryAvtive.pop()
        } else {
          this.hyryAvtive.splice(0, 1)
        }
        let item = this.hyrys.find(i => i.id == this.hyryAvtive[0].id)
        let ind = type == 'left' ? this.hyrys.indexOf(item) - 1 : this.hyrys.indexOf(item)
        if (ind < 0) {
          ind = this.hyrys.length - 1
        }
        this.hyryAvtive = []
        this.getHyrysActive(ind)
        $('.honor-list').stop().animate({ left: leftNum }, 0)
      }, 310);
    },

    // 核心客户标签页切换事件
    hxkhHandleClick (tab) {
      this.hxkhActive = tab.index * 1
      this.$refs.hxkhCardShow.setActiveItem(this.hxkhActive)
    },

  },
}
</script>
<style lang="less" scoped>
.home-banner {
  height: 924px;
  background: url("../../assets/images/home-bg1.png") no-repeat;
  background-size: 100% 100%;
  padding-top: 1px;
  position: relative;

  // 滚动通知
  .home-notice {
    height: 45px;
    line-height: 45px;
    background-color: #fff;
    box-shadow: 0px 3px 7px 0px rgba(0, 0, 0, 0.45);
    border-radius: 23px;
    padding: 0 20px;
    margin: 15px auto;
    box-sizing: border-box;
    .el-image {
      width: 30px;
      height: 25px;
      margin-right: 45px;
    }
    .home-notice-text {
      color: #484747;
      letter-spacing: 1px;
    }
    .home-notice-time {
      color: #434343;
      letter-spacing: 2px;
      margin-right: 20px;
    }
  }
}
// 卷轴
.home-one {
  width: 100%;
  max-width: 1204px;
  height: 482px;
  position: relative;
  margin: 18px auto 0;
  overflow: hidden;
  .home-jz {
    width: 60px;
    height: 462px;
    position: absolute;
    z-index: 2;
  }
  .home-jz-left {
    left: 46%;
  }
  .home-jz-right {
    right: 47%;
  }
  .home-jz-center {
    width: 50px;
    height: 420px;
    background: url("../../assets/images/home-bn1-center.jpg") no-repeat center;
    margin: 25px auto 0;
  }
}
// 核心业务
.home-two {
  height: 370px;
  margin-top: 55px;
  .home-hxyw {
    img {
      width: auto;
      height: auto;
      max-width: 100%;
      max-height: 100%;
    }
  }
}
// 荣誉图集
.home-three {
  height: 627px;
  background: url("../../assets/images/home-bg2.png") no-repeat;
  background-size: 100% 100%;
  .home-honor {
    width: 100%;
    height: 342px;
    position: relative;
    top: 30%;
    > i {
      position: absolute;
      z-index: 2;
      font-size: 60px;
      color: #ffffff;
      cursor: pointer;
      &.honor-left {
        left: -82px;
      }
      &.honor-right {
        right: -82px;
      }
    }
    .honor-box {
      width: 100%;
      height: 100%;
      overflow: hidden;
      position: relative;
      .honor-list {
        width: 140%;
        height: 100%;
        position: absolute;
        left: -20%;
        .honor-item {
          width: 13%;
          height: 155px;
          position: relative;
        }
        span {
          position: absolute;
          top: 10px;
          left: 10px;
        }
      }
    }
  }
}
// 核心优势
.home-four {
  background-color: #f1f1f1;
  .hxys-list {
    flex: 1;
  }
  .hxys-item {
    width: 32%;
    height: 226px;
    margin: 10px 0;
    background-repeat: no-repeat;
    background-size: cover;
    border-radius: 12px;
    overflow: hidden;
    position: relative;
    a {
      flex-direction: column;
    }
    &:hover {
      .hxys-img {
        opacity: 0;
        margin-top: -70%;
      }
      .hxys-line {
        opacity: 0;
      }
      .hxys-info {
        top: 10%;
        opacity: 1;
      }
    }
    .hxys-img,
    .hxys-line,
    .hxys-title,
    .hxys-info {
      transition: all 0.3s;
    }
    .hxys-line {
      width: 31px;
      height: 7px;
      background: #ffffff;
      border-radius: 4px;
      margin: 20px 0;
    }
    .hxys-title {
      font-size: 20px;
      font-weight: bold;
      color: #fff;
    }
    .hxys-info {
      width: 100%;
      position: absolute;
      top: 50%;
      left: 0;
      opacity: 0;
      p {
        padding: 0 20px;
        margin-top: 60px;
        line-height: 18px;
        letter-spacing: 1px;
        text-indent: 28px;
        font-size: 14px;
        color: #fff;
        -webkit-line-clamp: 6;
        max-height: 108px;
      }
    }
  }
}
// 新闻动态
.home-five {
  .home-xwdt {
    width: 70%;
    /deep/ .el-tabs__header {
      margin: 0 0 40px;
    }
    .xwdt-item {
      padding: 30px 0;
      margin-left: 40px;
      border-bottom: 1px solid #cccccc;
      &:hover {
        .news-title {
          color: #f1b54c;
          font-weight: bold;
          &::before {
            background-color: #f1b54c;
          }
        }
      }
      p {
        line-height: 24px;
        .news-title {
          width: 88%;
          font-size: 18px;
          color: #333;
          position: relative;
          &::before {
            content: "";
            position: absolute;
            left: -40px;
            top: 7px;
            width: 11px;
            height: 11px;
            background-color: #b4afaf;
            border-radius: 50%;
          }
        }
        .news-time {
          color: #999999;
        }
        .news-info {
          width: 80%;
          color: #666666;
        }
        .news-time,
        .news-info {
          font-size: 14px;
        }
        .el-image {
          height: 14px;
          margin: 6px 18px 0 0;
        }
      }
    }
    .news-btn {
      float: right;
      margin: 37px 0 0 0;
    }
  }
}
// 业务分布
.home-six {
  height: 700px;
  background: url("../../assets/images/home-bg3.png") no-repeat;
  background-size: 100% 100%;
  .home-title {
    margin-top: 80px;
    align-self: flex-start;
  }
}
// 关于我们
.home-seven {
  .home-gywm {
    flex: 1;
    height: 242px;
    background-color: #f8d38f;
    border-radius: 121px;
    padding: 0 72px 0 15px;
    position: relative;
    p {
      margin-left: 37px;
      line-height: 25px;
      color: #242424;
      font-size: 16px;
      letter-spacing: 2px;
    }
    .gywm-btn {
      position: absolute;
      right: 10%;
      bottom: -10px;
    }
  }
}
// 核心客户
.home-eight {
  background-color: #f1f1f1;
  .home-hxkh {
    flex: 1;
    .hxkh-list {
      .hxkh-item {
        width: 17%;
        height: 72px;
        margin: 10px 0;
        padding: 4px;
        background: #ffffff;
        border: 3px solid #f1b54c;
        border-radius: 40px;
        .hxkh-logo {
          max-height: 60px;
          max-width: 160px;
        }
        .hxkh-name {
          font-weight: bold;
          letter-spacing: 2px;
          font-size: 18px;
          color: #4c4a4a;
          text-align: center;
        }
      }
    }
    /deep/ .el-carousel--horizontal {
      overflow-y: hidden;
      .el-carousel__container {
        height: 320px;
      }
    }
    /deep/ .el-carousel__button {
      background-color: #575757;
      width: 11px;
      height: 11px;
      border-radius: 50%;
    }
  }
}
// 新闻动态+关于我们 按钮
.home-btns {
  position: relative;
  overflow: hidden;
  .el-button {
    width: 80px;
    height: 40px;
    font-size: 16px;
  }
  &:hover {
    img {
      right: 0;
    }
    .el-button--warning {
      left: 0;
    }
  }
  img,
  .el-button--warning {
    position: absolute;
    transition: all 0.3s;
    top: 50%;
  }
  img {
    right: 100%;
    z-index: 2;
    transform: translate(-50%, -50%);
  }
  .el-button--warning {
    left: 100%;
    transform: translateY(-50%);
  }
}
// 新闻动态+核心客户 标签页
/deep/ .el-tabs__header {
  margin: 0 0 70px;
  .el-tabs__nav-wrap {
    &::after {
      display: none;
    }
    .el-tabs__nav-scroll {
      float: right;
      .el-tabs__active-bar {
        height: 3px;
        background-color: #f1b54c;
      }
      .el-tabs__item {
        height: 36px;
        font-size: 16px;
        color: #666666;
        position: relative;
        &:hover {
          color: #f1b54c;
        }
        .el-divider--vertical {
          position: absolute;
          top: 37%;
          right: 0;
          margin: 0;
        }
        &.is-active {
          font-weight: bold;
          color: #f1b54c;
        }
      }
    }
  }
}

@media (max-width: 1399px) and (min-width: 1300px) {
  .home-three {
    .home-honor {
      > i {
        &.honor-left {
          left: -50px;
        }
        &.honor-right {
          right: -50px;
        }
      }
    }
  }
}
@media (max-width: 1299px) {
  .home-banner {
    > .content:first-child {
      width: 98%;
    }
  }
  .home-three {
    .content {
      width: 90%;
      .home-honor {
        > i {
          &.honor-left {
            left: -50px;
          }
          &.honor-right {
            right: -50px;
          }
        }
      }
    }
  }
  .home-four {
    .hxys-item {
      &:hover {
        .hxys-img {
          margin-top: -75%;
        }
      }
    }
  }
}
</style>