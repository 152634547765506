import Vue from 'vue'
import VueRouter from 'vue-router'
import store from '../store/index'
import Home from '../views/Home/Index.vue'
import MainChild from '../components/MainChild.vue'
Vue.use(VueRouter)

const routes = [
  // 首页
  {
    path: '/',
    name: 'Home',
    component: Home
  },
  // 公司概况
  {
    path: '/company',
    name: 'Company',
    redirect: { path: "/company/team" },
    component: MainChild,
    children: [
      {
        path: '/company/team',
        name: 'CompanyTeam',
        component: () => import('../views/Company/Team.vue'),
      },
      {
        path: '/company/profile',
        name: 'CompanyProfile',
        component: () => import('../views/Company/Profile.vue'),
        meta: {
          content: {
            keywords: "新三板，新三板服务，新三板服务联盟，股权投资，新三板资讯，新三板动态，新三板新闻，新三板在线，新三板挂牌，新三板路演，三板，三板市场，新三板联盟，新三板融资",
            description: "新三板创新服务联盟是由智慧财富(北京)资本管理有限公司发起,多家业内领先的证券公司、会计师事务所、律师事务所、评估公司、商会协会、开发区、基金公司等机构联合成立的中小企业新三板一站式服务平台，各业务机构在行业中处于领先地位，对新三板业务具有权威运作经验，拥有超高的执行能力，执业经验和执业质量。",
          },
        }
      },
      {
        path: '/company/groupProfile',
        name: 'CompanyGroupProfile',
        component: () => import('../views/Company/GroupProfile.vue'),
      },
      {
        path: '/company/organizationChart',
        name: 'OrganizationChart',
        component: () => import('../views/Company/OrganizationChart.vue'),
      },
      {
        path: '/company/chairmanProfile',
        name: 'ChairmanProfile',
        component: () => import('../views/Company/ChairmanProfile.vue'),
      },
      {
        path: '/company/concept',
        name: 'Concept',
        component: () => import('../views/Company/Concept.vue'),
      },
    ]
  },
  // 资质荣誉
  {
    path: '/honour',
    name: 'Honour',
    redirect: { path: "/certificate" },
    component: MainChild,
    children: [
      {
        path: '/certificate',
        name: 'Certificate',
        component: () => import('../views/Honour/Certificate.vue'),
      },
      {
        path: '/publishingBooks',
        name: 'PublishingBooks',
        component: () => import('../views/Honour/PublishingBooks.vue'),
      },
      {
        path: '/topic',
        name: 'Topic',
        component: () => import('../views/Honour/Topic.vue'),
      },
      {
        path: '/honourCertificate',
        name: 'HonourCertificate',
        component: () => import('../views/Honour/HonourCertificate.vue'),
      },

    ]
  },
  // 新闻动态
  {
    path: '/news',
    name: 'News',
    redirect: { path: "/assessment" },
    component: MainChild,
    children: [
      {
        path: '/companyNews',
        name: 'company',
        component: () => import('../views/News/Company.vue'),
        meta: {
          content: {
            keywords: "新三板，新三板服务，新三板服务联盟，股权投资，新三板资讯，新三板动态，新三板新闻，新三板在线，新三板挂牌，新三板路演，三板，三板市场，新三板联盟，新三板融资",
            description: "新三板创新服务联盟是由智慧财富(北京)资本管理有限公司发起,多家业内领先的证券公司、会计师事务所、律师事务所、评估公司、商会协会、开发区、基金公司等机构联合成立的中小企业新三板一站式服务平台，各业务机构在行业中处于领先地位，对新三板业务具有权威运作经验，拥有超高的执行能力，执业经验和执业质量。",
          },
        }
      },
      {
        path: '/industryNews',
        name: 'industry',
        component: () => import('../views/News/Industry.vue'),
        meta: {
          content: {
            keywords: "新三板，新三板服务，新三板服务联盟，股权投资，新三板资讯，新三板动态，新三板新闻，新三板在线，新三板挂牌，新三板路演，三板，三板市场，新三板联盟，新三板融资",
            description: "新三板创新服务联盟是由智慧财富(北京)资本管理有限公司发起,多家业内领先的证券公司、会计师事务所、律师事务所、评估公司、商会协会、开发区、基金公司等机构联合成立的中小企业新三板一站式服务平台，各业务机构在行业中处于领先地位，对新三板业务具有权威运作经验，拥有超高的执行能力，执业经验和执业质量。",
          },
        }
      },
      {
        path: '/caseNews',
        name: 'case',
        component: () => import('../views/News/Case.vue'),
        meta: {
          content: {
            keywords: "新三板，新三板服务，新三板服务联盟，股权投资，新三板资讯，新三板动态，新三板新闻，新三板在线，新三板挂牌，新三板路演，三板，三板市场，新三板联盟，新三板融资",
            description: "新三板创新服务联盟是由智慧财富(北京)资本管理有限公司发起,多家业内领先的证券公司、会计师事务所、律师事务所、评估公司、商会协会、开发区、基金公司等机构联合成立的中小企业新三板一站式服务平台，各业务机构在行业中处于领先地位，对新三板业务具有权威运作经验，拥有超高的执行能力，执业经验和执业质量。",
          },
        }
      },
      {
        path: '/news/newsDetails',
        name: 'news_trends',
        component: () => import('../components/NewsDetails.vue'),
        meta: { isDetails: true }
      },
    ]
  },
  // 服务中心
  {
    path: '/service',
    name: 'Service',
    redirect: { path: "/assessment" },
    component: MainChild,
    children: [
      {
        path: '/corestrengths',
        name: 'CoreStrengths',
        component: () => import('../views/Service/CoreStrengths.vue'),
        meta: {
          content: {
            keywords: "新三板，新三板服务，新三板服务联盟，股权投资，新三板资讯，新三板动态，新三板新闻，新三板在线，新三板挂牌，新三板路演，三板，三板市场，新三板联盟，新三板融资",
            description: "新三板创新服务联盟是由智慧财富(北京)资本管理有限公司发起,多家业内领先的证券公司、会计师事务所、律师事务所、评估公司、商会协会、开发区、基金公司等机构联合成立的中小企业新三板一站式服务平台，各业务机构在行业中处于领先地位，对新三板业务具有权威运作经验，拥有超高的执行能力，执业经验和执业质量。",
          },
        }
      },
      {
        path: '/business',
        name: 'Business',
        component: () => import('../views/Service/Business.vue'),
      },
      {
        path: '/assess',
        name: 'Assess',
        component: () => import('../views/Service/Assess.vue'),
      },
      {
        path: '/theory',
        name: 'Theory',
        component: () => import('../views/Service/Theory.vue'),
      },
    ]
  },
  // 金融创新
  {
    path: '/financial',
    name: 'Financial',
    redirect: { path: "/assessment" },
    component: MainChild,
    children: [
      {
        path: '/trust',
        name: 'Trust',
        component: () => import('../views/Financial/Trust.vue'),
      },
      {
        path: '/securitization',
        name: 'Securitization',
        component: () => import('../views/Financial/Securitization.vue'),
      },
      {
        path: '/lease',
        name: 'Lease',
        component: () => import('../views/Financial/Lease.vue'),
      },
      {
        path: '/pledgeLoan',
        name: 'PledgeLoan',
        component: () => import('../views/Financial/PledgeLoan.vue'),
      },
      {
        path: '/pledgeFinancing',
        name: 'PledgeFinancing',
        component: () => import('../views/Financial/PledgeFinancing.vue'),
      }
    ]
  },
  // 党建文化
  {
    path: '/partyCulture',
    name: 'PartyCulture',
    redirect: { path: "/partyWork" },
    component: MainChild,
    children: [
      {
        path: '/freeBags',
        name: 'benefit',
        component: () => import('../views/PartyCulture/FreeBags.vue'),
      },
      {
        path: '/fund',
        name: 'Fund',
        component: () => import('../views/PartyCulture/Fund.vue'),
      },
      {
        path: '/partyWork',
        name: 'branch-news',
        component: () => import('../views/PartyCulture/PartyWork.vue'),
      },
      {
        path: '/pilot',
        name: 'Pilot',
        component: () => import('../views/PartyCulture/Pilot.vue'),
      },
      {
        path: '/underWork',
        name: 'UnderWork',
        component: () => import('../views/PartyCulture/UnderWork.vue'),
      },
      {
        path: '/partyCultureDetails',
        name: 'party_culture',
        component: () => import('../components/NewsDetails.vue'),
        meta: { isDetails: true }
      },
    ]
  },
  // 经典客户
  {
    path: '/classicCustomer',
    name: 'ClassicCustomer',
    redirect: { path: "/partners" },
    component: MainChild,
    children: [
      {
        path: '/partners',
        name: 'zlhzhb',
        component: () => import('../views/ClassicCustomer/Partners.vue'),
        meta: {
          content: {
            keywords: "新三板，新三板服务，新三板服务联盟，股权投资，新三板资讯，新三板动态，新三板新闻，新三板在线，新三板挂牌，新三板路演，三板，三板市场，新三板联盟，新三板融资",
            description: "新三板创新服务联盟是由智慧财富(北京)资本管理有限公司发起,多家业内领先的证券公司、会计师事务所、律师事务所、评估公司、商会协会、开发区、基金公司等机构联合成立的中小企业新三板一站式服务平台，各业务机构在行业中处于领先地位，对新三板业务具有权威运作经验，拥有超高的执行能力，执业经验和执业质量。",
          },
        }
      },
      {
        path: '/soes',
        name: 'gyqy',
        component: () => import('../views/ClassicCustomer/Soes.vue'),
      },
      {
        path: '/brandCustomers',
        name: 'ppkh',
        component: () => import('../views/ClassicCustomer/BrandCustomers.vue'),
        meta: {
          content: {
            keywords: "新三板，新三板服务，新三板服务联盟，股权投资，新三板资讯，新三板动态，新三板新闻，新三板在线，新三板挂牌，新三板路演，三板，三板市场，新三板联盟，新三板融资",
            description: "新三板创新服务联盟是由智慧财富(北京)资本管理有限公司发起,多家业内领先的证券公司、会计师事务所、律师事务所、评估公司、商会协会、开发区、基金公司等机构联合成立的中小企业新三板一站式服务平台，各业务机构在行业中处于领先地位，对新三板业务具有权威运作经验，拥有超高的执行能力，执业经验和执业质量。",
          },
        }
      },
      {
        path: '/academicInstitution',
        name: 'kyys',
        component: () => import('../views/ClassicCustomer/AcademicInstitution.vue'),
      },
      {
        path: '/biologicalMedicine',
        name: 'swyy',
        component: () => import('../views/ClassicCustomer/BiologicalMedicine.vue'),
      },
      {
        path: '/intangibleAssets',
        name: 'tswxzc',
        component: () => import('../views/ClassicCustomer/IntangibleAssets.vue'),
      },
    ]
  },
  // 商务合作
  {
    path: '/cooperation',
    name: 'Cooperation',
    component: () => import('../views/Cooperation/Index.vue'),
  },
  // 联系我们
  {
    path: '/contactUs',
    name: 'ContactUs',
    redirect: { path: "/branch" },
    component: MainChild,
    children: [
      {
        path: '/branch',
        name: 'BusinessMap',
        component: () => import('../components/BusinessMap.vue'),
        meta: {
          content: {
            keywords: "新三板，新三板服务，新三板服务联盟，股权投资，新三板资讯，新三板动态，新三板新闻，新三板在线，新三板挂牌，新三板路演，三板，三板市场，新三板联盟，新三板融资",
            description: "新三板创新服务联盟是由智慧财富(北京)资本管理有限公司发起,多家业内领先的证券公司、会计师事务所、律师事务所、评估公司、商会协会、开发区、基金公司等机构联合成立的中小企业新三板一站式服务平台，各业务机构在行业中处于领先地位，对新三板业务具有权威运作经验，拥有超高的执行能力，执业经验和执业质量。",
          },
        }
      },
      {
        path: '/hq',
        name: 'Hq',
        component: () => import('../views/ContactusUs/Hq.vue'),
        meta: {
          content: {
            keywords: "新三板，新三板服务，新三板服务联盟，股权投资，新三板资讯，新三板动态，新三板新闻，新三板在线，新三板挂牌，新三板路演，三板，三板市场，新三板联盟，新三板融资",
            description: "新三板创新服务联盟是由智慧财富(北京)资本管理有限公司发起,多家业内领先的证券公司、会计师事务所、律师事务所、评估公司、商会协会、开发区、基金公司等机构联合成立的中小企业新三板一站式服务平台，各业务机构在行业中处于领先地位，对新三板业务具有权威运作经验，拥有超高的执行能力，执业经验和执业质量。",
          },
        }
      },
    ]
  },
  // 个人中心
  {
    path: '/my',
    name: "my",
    component: () => import('../views/my/index.vue'),
    meta: {
      isStatic: true,
      loginAuth: true
    }
  }
]

const router = new VueRouter({
  mode: 'history',
  routes,
  // 解决多个页面相互滚动影响
  scrollBehavior () {
    return { x: 0, y: 0 }
  },

})
// 设置收录关键字
router.beforeEach((to, from, next) => {
  let head = document.getElementsByTagName('head');
  let meta = document.createElement('meta');
  let isLogin = store.getters.getUser
  if (to.matched.some(record => record.meta.loginAuth)) {
    if (!isLogin) {
      next({
        path: from.path,
        query: { redirect: to.fullPath } // 将跳转的路由path作为参数，登录成功后跳转到该路由
      })
      store.dispatch("setShowLogin", true)
    } else {
      next()
    }
  }

  if (to.meta.content) {
    document.querySelector('meta[name="keywords"]').setAttribute('content', to.meta.content.keywords)
    document.querySelector('meta[name="description"]').setAttribute('content', to.meta.content.description)
  } else {
    document.querySelector('meta[name="keywords"]').setAttribute('content', '资产评估,无形资产评估,资产评估公司,资产评估事务所,资产评估机构,知识产权评估,商标评估,专利评估,品牌价值评估,专利增资,专利技术评估，股权评估，车辆评估，整体评估，医疗设备评估，房地产评估，单项设备评估，损失评估')
    document.querySelector('meta[name="description"]').setAttribute('content', '北京中金浩资产评估有限责任公司是由财政部授予资产评估资格（证书编号：NO.11060018）的专业权威评估机构。中金浩评估公司经过多年发展逐步形成了以知识产权评估、质押、融资新渠道研究为核心目标的发展方向，做中国无形资产评估专家！')
  }
  meta.content = to.meta.content;
  head[0].appendChild(meta)
  next()
})
export default router
