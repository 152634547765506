<template>
  <header>
    <div class="my-header">
      <!-- 导航 -->
      <div class="content align-center ">
        <div class="logo">
          <router-link to="/">
            <img :src="$base + getConfigs.logo" alt="logo" />
          </router-link>
        </div>
        <div class="logo" style="margin-left: 20px;">
          <router-link to="/">
            <img
              :src="require('@/assets/images/logo-wzw.png')"
              alt="logo-wzw"
            />
          </router-link>
        </div>
        <el-menu
          :default-active="activeIndex"
          background-color="#97846c"
          text-color="#fff"
          active-text-color="#FBD690"
          class="el-menu-demo"
          mode="horizontal"
          router
        >
          <template v-for="(nav, index) in navs">
            <!-- 同一父元素下的多个分支（v-if、v-else-if、v-else）分配一个唯一的key值，避免Vue在更新组件时发生混淆 -->
            <el-menu-item
              v-if="!nav.client_title.length"
              :key="index"
              :index="nav.route_url"
            >
              {{ nav.name }}
            </el-menu-item>
            <el-submenu v-else :key="index + '1'" :index="nav.route_url">
              <template slot="title">{{ nav.name }}</template>
              <el-menu-item
                v-for="(navChild, index1) in nav.client_title"
                :key="index1"
                :index="navChild.route_url"
              >
                {{ navChild.name }}
              </el-menu-item>
            </el-submenu>
          </template>
        </el-menu>
      </div>
      <!-- 登录 -->
      <div class="login align-center">
        <el-button v-if="!getUser" type="text" @click="showLogin">
          登录
        </el-button>
        <el-dropdown
          v-else
          trigger="click"
          class="align-center"
          @command="logout"
        >
          <span class="el-dropdown-link">
            {{ getUser.user.phone }}
            <i class="el-icon-arrow-down el-icon--right"></i>
          </span>
          <el-dropdown-menu slot="dropdown">
            <el-dropdown-item>
              <router-link to="/my">个人中心</router-link>
            </el-dropdown-item>
            <el-dropdown-item command="logout">退出登录</el-dropdown-item>
          </el-dropdown-menu>
        </el-dropdown>
      </div>
    </div>
    <div style="height:80px"></div>
    <Login />
  </header>
</template>

<script>
import { mapGetters } from 'vuex';
import { loginOut } from '@/api';
import Login from '@/views/Login/Index.vue';
export default {
  components: {
    Login
  },
  props: {
    navs: Array
  },
  data() {
    return {
      activeIndex: ''
    };
  },
  computed: {
    ...mapGetters(['getNavItem', 'getConfigs', 'getUser'])
  },
  mounted() {
    this.getActive(this.$route);
    // 监听sessionStorage
    window.addEventListener('setItem', () => {
      let news_type = sessionStorage.getItem('news_type');
      this.findNav(this.$router.options.routes, news_type);
    });
  },
  watch: {
    $route(to, from) {
      this.getActive(to);
    }
  },
  methods: {
    // 点击切换高亮显示
    getActive(route) {
      if (route.meta.isDetails) {
        if (sessionStorage.getItem('route_url')) {
          this.activeIndex = sessionStorage.getItem('route_url');
        }
      } else {
        this.activeIndex = route.path;
      }
    },
    // 直接进入新闻详情页高亮显示
    findNav(routes, news_type) {
      routes.forEach((item) => {
        if (item.name == news_type) {
          this.activeIndex = item.path;
          return this.activeIndex;
        }
        if (item.children) {
          this.findNav(item.children, news_type);
        }
      });
      return this.activeIndex;
    },
    // 展示登录框
    showLogin() {
      this.$store.dispatch('setShowLogin', true);
    },
    // 退出登录
    async logout(command) {
      if (command == 'logout') {
        let { status } = await loginOut();
        if (status == 200) {
          if (this.$route.meta.loginAuth) {
            this.$router.push('/');
          }
          this.$store.dispatch('setUser', null);
          this.$store.dispatch('setShowLogin', false);
        }
      }
    }
  }
};
</script>

<style scoped lang="less">
.my-header {
  width: 100%;
  height: 80px;
  background-color: #97846c;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 99;
}

/deep/.el-menu-demo {
  &.el-menu--horizontal {
    border-bottom: none;
    height: 100%;
    .el-menu-item,
    .el-submenu__title {
      height: 80px;
      line-height: 80px;
      padding: 0 10px;
    }
    .el-menu-item:hover,
    .el-submenu__title:hover {
      color: #fbd690 !important;
    }
  }
  .el-submenu__title i {
    color: #ffffff;
    margin-top: 0;
    margin-left: 3px;
  }
}

.el-menu--horizontal {
  .el-menu {
    .el-menu-item {
      color: #383838 !important;
      background-color: #f2f2f2 !important;
      text-align: center;
      &:hover {
        color: #ffffff !important;
        background-color: #fbd690 !important;
      }
      &.is-active {
        color: #ffffff !important;
        background-color: #fbd690 !important;
      }
    }
  }
}

.login {
  width: auto;
  height: 100%;
  position: absolute;
  right: 3%;
  top: 0;
  transition: border-color 0.3s, background-color 0.3s, color 0.3s;
  button,
  .el-dropdown {
    color: #fff;
    font-size: 14px;
    cursor: pointer;
  }
  button:hover {
    color: #fbd690 !important;
  }

  .el-dropdown {
    height: 100%;
    display: flex;
  }
}

@media (min-width: 1500px) {
  /deep/.el-menu-demo {
    margin-left: 45px;
  }
}

@media (max-width: 1499px) and (min-width: 1400px) {
  /deep/.el-menu-demo {
    margin-left: 50px;
    &.el-menu--horizontal {
      .el-submenu__title {
        padding: 0 10px;
      }
    }
  }
}
@media (max-width: 1399px) and (min-width: 1300px) {
  /deep/.el-menu-demo {
    margin-left: 30px;
    &.el-menu--horizontal {
      .el-submenu__title {
        padding: 0 8px;
      }
    }
  }
}
@media (max-width: 1299px) {
  /deep/.el-menu-demo {
    margin-left: 20px;
    &.el-menu--horizontal {
      .el-submenu__title {
        padding: 0 8px;
      }
    }
  }
}
</style>
<style lang="less">
.el-menu--horizontal {
  .el-menu--popup {
    min-width: 100px;
    background-color: #f2f2f2 !important;
    box-shadow: 0px 5px 4px 0px rgba(0, 0, 0, 0.4);
    border-radius: 4px;
    margin-top: 0;
  }
}
.el-dropdown-menu.el-popper {
  border: none;
  .el-dropdown-menu__item:hover {
    color: #ffffff;
    background-color: #fbd690;
  }
}
</style>
