// 二级路由显示
<template>
  <keep-alive>
    <router-view></router-view>
  </keep-alive>
</template>
<script>
export default {
  name: 'MainChild',
}
</script>