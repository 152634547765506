import request from '@/utils/request.js';

// 首页接口
// 网站配置
export const getConfig = () => {
  return request({
    method: 'get',
    url: '/config/site'
  })
}

// 头部导航
export const getNavs = () => {
  return request({
    method: 'get',
    url: '/get-column',
  })
}

// 首页新闻
export const getHomeNews = () => {
  return request({
    method: 'get',
    url: '/get-news',
  })
}

// 企业协会
export const getCase = (code) => {
  return request({
    method: 'post',
    url: '/case',
    data: { code }
  })
}

// 核心优势
export const getCore = (type) => {
  return request({
    method: 'get',
    url: `/core/${type}`,
  })
}

// 核心客户
export const getCust = () => {
  return request({
    method: 'get',
    url: '/cust'
  })
}

// 菜单内容
export const getInfo = (navId) => {
  return request({
    method: 'get',
    url: `/menutitleone/${navId}`
  })
}

// 新闻
export const getNews = ({ type, page }) => {
  return request({
    method: 'get',
    url: `/news/${type}/${page}`
  })
}

// 单条新闻
export const getOneNews = ({ type, id }) => {
  return request({
    method: 'get',
    url: `/onenews/${type}/${id}`
  })
}

// 公司概括
// 社会成就
export const getSocal = () => {
  return request({
    method: 'get',
    url: '/get-socal'
  })
}
// 公司团队
export const getTeam = () => {
  return request({
    method: 'get',
    url: '/get-team'
  })
}

// 服务中心接口
// 业务领域
export const getBusiness = () => {
  return request({
    method: 'get',
    url: '/menutitle/ywly'
  })
}

// 金融创新接口
// 质押贷款
export const getProduct = () => {
  return request({
    method: 'get',
    url: '/pc-product'
  })
}

// 登录信息
export const getUser = (data) => {
  return request({
    method: 'post',
    url: '/login',
    data
  })
}

// 发送验证码
export const sendCode = (phone) => {
  return request({
    method: 'post',
    url: '/sms',
    data: { phone }
  })
}

// 修改用户信息
export const updateUser = (data) => {
  return request({
    method: 'post',
    url: '/user',
    headers: { token: true },
    data
  })
}

// 退出登录
export const loginOut = () => {
  return request({
    method: 'post',
    url: '/loginOut',
    headers: { token: true }
  })
}

