<template>
  <div class="c-reel">
    <div class="view" ref="view" :style="{width: viewWidth}">
      <div class="center-jz" ref="reel" :style="scrollStyle">
        <slot></slot>
      </div>
      <img class="jz left-jz" src="../assets/images/home-bn1-left.png" alt="左卷轴" :style="{left: jzLeft}" />
      <img class="jz right-jz" src="../assets/images/home-bn1-right.png" alt="右卷轴" :style="{right: jzRight}" />
    </div>
  </div>
</template>
<script>
export default {
  data () {
    return {
      viewWidth: "", // 可见区域的样式
      scrollStyle: {}, // 卷轴的样式
      jzLeft: "", // 左卷轴左偏移
      jzRight: "" // 右卷轴右偏移
    };
  },
  props: {
    start: {
      type: Boolean,
      default: true // 控制卷轴开始
    },
    showAll: {
      type: Boolean,
      default: true // 控制卷轴显示样式，显示全部true还是显示部分false
    },
    speed: {
      type: String,
      default: "fast" // 卷轴打开速度 slow fast
    }
  },
  mounted () {
    this.handleTransform();
    if (this.start == true) {
      this.handleStart();
    }
  },
  methods: {
    /**
     * 卷轴打开动画
     */
    handleStart () {
      let scrollWidth = this.$refs.reel.clientWidth; // 获取展开卷轴的宽度
      let viewWidth = this.$refs.view.clientWidth; // 可见区域的宽度
      var speed = 10;
      if (this.speed == "fast") {
        speed = 3;
      } else {
        speed = 10;
      }
      let time = setInterval(() => {
        viewWidth += 1;
        this.viewWidth = viewWidth + "px";
        // view样式渲染完成后执行卷轴的移动操作
        this.$nextTick(() => {
          this.handleTransform();
        });
        if (viewWidth >= scrollWidth) {
          clearInterval(time);
          if (!this.showAll) {
            let endPosition = 0;
            let endTime = setInterval(() => {
              endPosition += 0.5;
              this.jzLeft = "-" + endPosition.toFixed(2) + "px";
              this.jzRight = "-" + endPosition.toFixed(2) + "px";
              if (Math.abs(endPosition - 6) < 0.01) {
                clearInterval(endTime);
              }
            }, speed);
          }
        }
      }, speed);
    },
    /**
     * 处理内部展开卷轴的移动位置
     * 展开卷轴的宽度-可见区域的宽度，再除以2得到的值就是内部卷轴应该向左移动的位置
     * 随着可见区域的扩大，向左移动位置应该动态改变，保证卷轴处于中间位置
     */
    handleTransform () {
      let viewWidth = this.$refs.view.clientWidth; // 可见区域的宽度
      let scrollWidth = this.$refs.reel.clientWidth; // 获取展开卷轴的宽度
      let translateX = (scrollWidth - viewWidth) / 2;
      this.scrollStyle = {
        transform: "translate(-" + translateX + "px,-49%)"
      };
    }
  },
  watch: {
    start: function (e) {
      if (e == true) {
        this.handleStart();
      }
    }
  }
};
</script>
<style lang="less" scoped>
.c-reel {
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  width: 100%;
  height: 462px;
  .view {
    position: absolute;
    top: 0;
    left: 50%;
    transform: translateX(-50%);
    width: 82px;
    height: 100%;
    overflow: hidden;
    .center-jz {
      width: 61vw;
      height: 420px;
      background: url("../assets/images/home-bn1-center.jpg") no-repeat center;
      background-size: 100% 100%;
      overflow: hidden;
      position: relative;
      top: 50%;
    }
  }
  .jz {
    position: absolute;
    top: 0;
    width: 60px;
    height: 100%;
    &.left-jz {
      left: -4px;
    }
    &.right-jz {
      right: -7px;
    }
  }
}
</style>