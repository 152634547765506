<template>
  <!-- 在线咨询 -->
  <section class="home-consulting">
    <div class="consulting-logo">
      <img class="wh100" :src="$base+getConfigs.logo" alt='logo' />
    </div>

    <div class="consulting-content">
      <div class="consulting-title consulting-btn flex-center" @click="consultingClick">
        <img :src="require('@/assets/images/home-consulting.png')" alt='咨询' />
        <span>在线咨询</span>
      </div>
      <ul class="consulting-list">
        <li class="consulting-item consulting-btn" v-for="(item,index) in consultings" :key="index"
          @click="consultingClick"><a href="javascript:;">{{item}}</a></li>
      </ul>
    </div>
  </section>
</template>

<script>
import { mapGetters } from "vuex"
export default {

  data () {
    return {
      consultings: ['资产评估', '无形评估', '单项评估', '价值评估', '项目评估', '其它评估'],
    };
  },
  computed: {
    ...mapGetters(['getConfigs', 'getCores'])
  },

};
</script>

<style scoped lang="less">
// 在线咨询
.home-consulting {
  position: fixed;
  z-index: 8;
  right: 5%;
  bottom: 10%;
  width: 118px;
  .consulting-logo {
    text-align: center;
    padding: 8px 10px;
    background-color: #fff;
    box-shadow: 0px -3px 8px 0px rgba(34, 23, 20, 0.25);
    border-top-left-radius: 6px;
    border-top-right-radius: 6px;
    margin-bottom: 7px;
  }
  .consulting-content {
    background: #f5f5f5;
    box-shadow: 0px 4px 4px 0px rgba(34, 23, 20, 0.25);
    border-bottom-left-radius: 6px;
    border-bottom-right-radius: 6px;
    .consulting-title {
      height: 60px;
      padding: 0 20px;
      background-color: #fff;
      cursor: pointer;
      span {
        font-size: 14px;
        font-weight: bold;
        color: #f1b54c;
        display: inline-block;
        margin-left: 10px;
        line-height: 16px;
      }
    }
    .consulting-list {
      padding: 0 15px;
      .consulting-item {
        text-align: center;
        font-size: 14px;
        padding: 15px 0;
        border-bottom: 1px solid #d9d9d9;
        a {
          color: #2f2f2f;
        }
        &:last-child {
          border-bottom: none;
        }
        &:hover {
          a {
            color: #f1b54c;
          }
        }
      }
    }
  }
}

@media (max-width: 1699px) and (min-width: 1500px) {
  .home-consulting {
    right: 2%;
  }
}
@media (max-width: 1499px) and (min-width: 1400px) {
  .home-consulting {
    right: 1%;
    width: 90px;
    .consulting-content {
      .consulting-title {
        padding: 0 8px;
      }
    }
  }
}
@media (max-width: 1399px) {
  .home-consulting {
    right: 3%;
    bottom: 25%;

    .consulting-content {
      .consulting-title {
        border-radius: 4px;
      }
      .consulting-list {
        display: none;
      }
    }
  }
}
</style>
