import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import './assets/styles/reset.css'
import './assets/styles/common.css'
import './assets/styles/common-media.css'
import './assets/fonts/fonts.css' // 华文行楷字体

// 时间戳转化
import * as moment from '@/utils/filter'

// 全局引入element库
import ElementUI from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css';
Vue.use(ElementUI);

// 引入swiper插件
import VueAwesomeSwiper from 'vue-awesome-swiper'
import 'swiper/dist/css/swiper.css'
Vue.use(VueAwesomeSwiper)

// 引入百度地图
import BaiduMap from 'vue-baidu-map'
Vue.use(BaiduMap, {
  // ak 是在百度地图开发者平台申请的密钥 详见 http://lbsyun.baidu.com/apiconsole/key */
  ak: 'Jr1oEDX7d3XLJOWCsiurFuQcIE2ARfA6'
})

// 图片地址
Vue.prototype.$base = process.env.VUE_APP_IMAGE_URL
// 站长之家
Vue.prototype.$cnzz = process.env.VUE_APP_CNZZ_URL
// 网站访问录入
Vue.prototype.$pvuv = process.env.VUE_APP_PVUV_URL

// 在线咨询
Vue.prototype.consultingClick = function () {
  $('div').on('click', '.consulting-btn', function () {
    window.open('http://p.qiao.baidu.com/cps/chat?siteId=940317&userId=564407&siteToken=f5a007b6ca8464d96807b58e6a27cfaa', 'windowForBridgeIM',
      'left=100,top=200,width=750,height=600,location=no,resizable=yes,status=no,toolbar=no,menubar=no')
  })
}

// 监听sessionStorage
Vue.prototype.resetSetItem = function (key, newVal) {
  if (key === 'news_type') {
    // 创建一个StorageEvent事件
    var newStorageEvent = document.createEvent('StorageEvent');
    const storage = {
      setItem: function (k, val) {
        sessionStorage.setItem(k, val);
        // 初始化创建的事件
        newStorageEvent.initStorageEvent('setItem', false, false, k, null, val, null, null);
        // 派发对象
        window.dispatchEvent(newStorageEvent)
      }
    }
    return storage.setItem(key, newVal);
  }
}

Vue.config.productionTip = false

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
