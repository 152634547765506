// 函数防抖
let timeout = null
export function debounce (fn, wait) {
  if (timeout !== null) clearTimeout(timeout)
  timeout = setTimeout(fn, wait)
}
// 函数节流
let prev = Date.now()
export function throttle (fn, delay) {
  let now = Date.now()
  if (now - prev > delay) {
    fn()
    prev = Date.now()
  }
}
// 等比例缩小图片
export function getImg (src) {
  var img_url = src
  var img = new Image()
  img.src = img_url
  return Math.ceil(img.height / img.width * 460) + 'px'
}