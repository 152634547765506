// 业务分布
<template>
  <div class="home-ywfb ">
    <div class="ywfb-map">
      <div
        v-for="(item, index) in ywfb"
        :key="index"
        class="flex-center ywfb-map-item"
        @click="changeArea(index)"
      >
        <div
          :class="[
            'ywfb-map-default',
            index == ywfbActive ? 'ywfb-map-ani' : ''
          ]"
        ></div>
        <span>{{ item.name }}</span>
      </div>
    </div>
    <div class="ywfb-list">
      <div class="ywfb-list-header justify-around">
        <div
          class="honor-arrow honor-left flex-center"
          @click="
            () => {
              this.$refs.newsCardShow.prev();
            }
          "
        >
          <el-image
            :src="require('@/assets/images/arrow-left.png')"
            alt=""
          ></el-image>
        </div>

        <el-carousel
          height="50px"
          indicator-position="none"
          arrow="never"
          :autoplay="false"
          ref="newsCardShow"
          :initial-index="ywfbActive"
          @change="
            (ind) => {
              ywfbActive = ind;
            }
          "
        >
          <el-carousel-item v-for="(item, index) in ywfb" :key="index">
            <h3 class="small">{{ item.name }}</h3>
          </el-carousel-item>
        </el-carousel>
        <div
          class="honor-arrow honor-right flex-center"
          @click="
            () => {
              this.$refs.newsCardShow.next();
            }
          "
        >
          <el-image
            :src="require('@/assets/images/arrow-right.png')"
            alt=""
          ></el-image>
        </div>
      </div>
      <div class="ywfb-list-content ">
        <p>
          {{ ywfb[ywfbActive] ? ywfb[ywfbActive].describe : '' }}
        </p>
      </div>
    </div>
  </div>
</template>

<script>
import { getCore } from '@/api';
export default {
  name: 'BusinessMap',
  data() {
    return {
      ywfb: [], // 业务分布数据
      ywfbActive: 1 // 业务分布轮播下标
    };
  },
  mounted() {
    this.getData();
  },
  methods: {
    async getData() {
      // 业务分布
      try {
        let ywfbRes = await getCore('fzjg');
        this.ywfb = ywfbRes.data;
      } catch (error) {
        console.log('网络错误');
      }
    },
    // 业务分布地区点击事件
    changeArea(ind) {
      this.ywfbActive = ind;
      this.$refs.newsCardShow.setActiveItem(ind);
    }
  }
};
</script>

<style scoped lang="less">
.home-ywfb {
  flex: 1;
  display: flex;
  .ywfb-map {
    width: 77%;
    height: 545px;
    background: url('../assets/images/home-ywfb1.png') no-repeat;
    background-size: 100% 100%;
    position: relative;
    cursor: pointer;
    .ywfb-map-item {
      width: 60px;
      height: 60px;
      position: absolute;
      &:nth-child(1) {
        top: 20%;
        right: 8%;
      }
      &:nth-child(2) {
        top: 32%;
        right: 28%;
      }
      &:nth-child(3) {
        bottom: 28%;
        right: 27%;
      }
      &:nth-child(4) {
        bottom: 36%;
        right: 18%;
      }
      &:nth-child(5) {
        bottom: 10%;
        right: 31%;
      }
      &:nth-child(6) {
        top: 44%;
        left: 39%;
      }
      &:nth-child(7) {
        bottom: 23%;
        left: 48%;
      }
      span {
        display: inline-block;
        position: absolute;
        z-index: 2;
      }
      .ywfb-map-default {
        position: absolute;
        z-index: 1;
        height: 20px;
        width: 20px;
        text-align: center;
        margin: 0 auto;
        border-radius: 50%;
        display: block;
        background: #ffc255;
        -webkit-box-shadow: 0 0 0 10px rgba(255, 207, 120, 0.5),
          0 0 0 20px rgba(255, 207, 120, 0.5), 0 0 0 30px rgba(255, 207, 120, 0);
        box-shadow: 0 0 0 10px rgba(255, 207, 120, 0.5),
          0 0 0 20px rgba(255, 207, 120, 0.5), 0 0 0 30px rgba(255, 207, 120, 0);
      }
      .ywfb-map-ani {
        -webkit-animation: ripple-white 1s linear infinite;
        animation: ripple-white 1s linear infinite;
        -webkit-transition: 0.5s linear;
      }
      @keyframes ripple-white {
        0% {
          -webkit-box-shadow: 0 0 0 0 rgba(255, 207, 120, 0.7),
            0 0 0 10px rgba(255, 207, 120, 0.7),
            0 0 0 20px rgba(255, 207, 120, 0.7);
          box-shadow: 0 0 0 0 rgba(255, 207, 120, 0.7),
            0 0 0 10px rgba(255, 207, 120, 0.7),
            0 0 0 20px rgba(255, 207, 120, 0.7);
          border-radius: 50%;
        }
        100% {
          -webkit-box-shadow: 0 0 0 10px rgba(255, 207, 120, 0.7),
            0 0 0 20px rgba(255, 207, 120, 0.7),
            0 0 0 30px rgba(255, 207, 120, 0);
          box-shadow: 0 0 0 10px rgba(255, 207, 120, 0.7),
            0 0 0 20px rgba(255, 207, 120, 0.7),
            0 0 0 30px rgba(255, 207, 120, 0);
          border-radius: 50%;
        }
      }
    }
  }
  .ywfb-list {
    width: 24%;
    height: 220px;
    border: 2px solid #ffc660;
    background-color: #ffc660;
    border-radius: 8px;
    align-self: flex-end;
    margin-bottom: 50px;

    .ywfb-list-header {
      border-top-left-radius: 8px;
      border-top-right-radius: 8px;
      height: 50px;
      background-color: #ffc660;
      cursor: pointer;
      .honor-arrow {
        width: 30%;
        height: 100%;
      }
      .el-carousel {
        flex: 1;
        text-align: center;
        line-height: 50px;
        color: #ffffff;
        font-size: 20px;
      }
    }
    .ywfb-list-content {
      padding: 0 40px;
      height: 170px;
      background-color: #ffffff;
      overflow: hidden;
      border-bottom-left-radius: 8px;
      border-bottom-right-radius: 8px;
      p {
        line-height: 30px;
        margin-top: 20px;
      }
    }
  }
}
</style>
