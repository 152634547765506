import Vue from 'vue'
import Vuex from 'vuex'
import { setItem, getItem } from '../utils/storage'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    navItem: null, // 当前导航对象
    configs: null, // 网站配置
    showLogin: false,//是否展示登录页面
    user: getItem("user") || null,// 用户信息
  },
  mutations: {
    SET_NAVITEM (state, payload) {
      state.navItem = payload
    },
    SET_CONFIGS (state, payload) {
      state.configs = payload
    },
    SET_SHOW_Login (state, val) {
      state.showLogin = val
    },
    SET_USER (state, user) {
      state.user = user
      setItem('user', user)
    }
  },
  actions: {
    setNavItem (context, val) {
      context.commit('SET_NAVITEM', val)
    },
    setConfigs (context, val) {
      context.commit('SET_CONFIGS', val)
    },
    setShowLogin (context, val) {
      context.commit('SET_SHOW_Login', val)
    },
    setUser (context, user) {
      context.commit('SET_USER', user)
    },
  },
  getters: {
    getNavItem: state => state.navItem,
    getConfigs: state => state.configs,
    getShowLogin: state => state.showLogin,
    getUser: state => state.user,
  },
  modules: {
  }
})
