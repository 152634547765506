
// 存
export const setItem = (key, value) => {
  window.localStorage.setItem(key, JSON.stringify(value));
}

// 取
export const getItem = (key) => {
  return JSON.parse(window.localStorage.getItem(key)) || null;
}

// 删
export const removeItem = (key) => {
  window.localStorage.removeItem(key);
}

// 清空
export const clear = (key) => {
  window.localStorage.clear();
}
